import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

import Routes from './routes';
import { roleModel } from './models';
import { ROLES } from './utils/constants';
import { GET_ALL_SITES, GET_LOCAL_STATES } from './graphQL/queries';
import { UPDATE_LAST_LOGIN_TIME } from './graphQL/mutations'; // Import the mutation
import { localStates, useLocalStates } from './graphQL/states/localStates';
import { PATH_NAME } from './routes/pathNames';
import ModalConfirmBody from './sharedComponents/modalConfirmBody';
import CustomModal from './sharedComponents/customModal';

function App() {
  const [isToken, setIsToken] = useState(true);
  const [open, setOpen] = useState(false);

  //  updateLastLoginTime mutation
  const [updateLastLoginTime] = useMutation(UPDATE_LAST_LOGIN_TIME);

  const {
    data: {
      localStates: { userRole, isLoggedIn }
    }
  } = useQuery(GET_LOCAL_STATES);

  useEffect(() => {
    if (window.self !== window.top) {
      document.body.innerHTML = `
        <p>Clickjacking protection: Not allowed to load in an iframe</p>
      `;
    }
  }, []);

  const onFocus = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setIsToken(false);
    } else {
      setIsToken(true);
    }
  };

  const onStorageChange = (e: any) => {
    onFocus();
    if (e.storageArea !== localStorage) {
      return;
    }
    if (e.key === 'userId') {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('storage', onStorageChange);
    window.addEventListener('storage', checkSiteChange);
  }, []);

  const { editSites, editUserRole, editIsLoggedIn } =
    useLocalStates(localStates);

  const [getAllSites] = useLazyQuery(GET_ALL_SITES, {
    onCompleted: data => updateSites(data)
  });

  const checkSiteChange = (e: any) => {
    if (e.type === 'storage' && e.key === 'defaultSiteId') {
      window.location.replace(PATH_NAME.DASHBOARD);
    }
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        editIsLoggedIn(true);
        // Call the updateLastLoginTime mutation after successful login
        updateLastLoginTime()
          .then(() => {
            console.log('Last login time updated successfully.');
          })
          .catch(error => {
            console.error('Failed to update last login time:', error);
          });
      })
      .catch(() => {
        editIsLoggedIn(false);
        localStorage.clear();
      });
  }, []);

  useEffect(() => {
    onFocus();
    if (isLoggedIn) getAllSites();
  }, [isLoggedIn]);

  useEffect(() => {
    onFocus();
  }, [isLoggedIn]);

  const updateSites = data => {
    const sites = data?.getAllSites;

    // Open CustomModal if sites are empty
    if (!sites || sites.length === 0) {
      setOpen(true);
      return;
    }
    let siteIdCheck = false;
    let role = localStorage.getItem('role') as roleModel;
    const defaultSiteId = localStorage.getItem('defaultSiteId');

    if (defaultSiteId) {
      if (defaultSiteId === 'all_sites' && userRole === ROLES.GLOBAL_ADMIN) {
        role = ROLES.GLOBAL_ADMIN;
        siteIdCheck = true;
      } else {
        sites.map(({ id, children, role: parentRole }) => {
          if (id === defaultSiteId) {
            role = parentRole;
            siteIdCheck = true;
          }
          if (children) {
            children.map(({ id: childId, role: childRole }) => {
              if (childId === defaultSiteId) {
                role = childRole;
                siteIdCheck = true;
              }
            });
          }
        });
      }
    } else {
      role = sites[0]?.role;
      let siteId = sites[0]?.id;
      if (role === ROLES.GLOBAL_ADMIN) siteId = 'all_sites';
      localStorage.setItem('defaultSiteId', siteId);
    }

    if (defaultSiteId && !siteIdCheck) {
      localStorage.removeItem('role');
      localStorage.removeItem('defaultSiteId');
      window.location.reload();
    }
    localStorage.setItem('sites', JSON.stringify(sites));
    editSites(sites);

    localStorage.setItem('role', role);
    const localRole = localStorage.getItem('role') as roleModel;
    const roleCheck = Object.values(ROLES).includes(localRole);

    editUserRole((roleCheck && localRole) || ROLES.GUEST);
  };

  const handleLogout = async () => {
    await Auth.signOut();
    localStorage.clear();
    window.location.replace(PATH_NAME.LOGIN);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Routes isLoggedIn={isToken ? isLoggedIn : false} />

      <CustomModal
        noCloseIcon
        confirmStyles
        isModalOpen={open}
        handleModalClose={handleModalClose}
        modalTitle="Deactivated Site"
        modalBody={
          <ModalConfirmBody
            prompt="All sites are deactivated. Please log out."
            handleConfirm={handleLogout}
            handleCancel={handleModalClose}
          />
        }
      />
    </>
  );
}

export default App;
