import { gql } from '@apollo/client';

export const GET_CONFIGURATION = gql`
  query getConfiguration {
    getConfiguration {
      id
      name
      value
    }
  }
`;
